import './WelcomeScreen.css';
import React, { useState } from 'react'
import Bot from '../../assets/images/bot.png';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

const jwt = require('jsonwebtoken');

const JWT_KEY = "vceruieuhfc87chdsrc87hu&*@YR*Y73tfyhi8";

const REACT_APP_BOT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

function WelcomeScreen({group, history}) {

    let optionValue = '';
    const { t } = useTranslation();

    //console.log("individual room : " + group.openingQuestion.individualRoom);

    const [loading, setLoading] = useState(false);

    const onChangeValue=(event) =>{

        //console.log(event.target.value);
        optionValue = event.target.value;
    }

    //Function to find the Room id elegible for participant
    const getRoom = async() => {
        if(!optionValue.length) {
            alert(t('main.plschooseoption'));
            return;
        }
        //console.log("inside get room. optionValue: " + optionValue);
        setLoading(true);

        let payload, token;
        const groupid = group._id;
        const type = optionValue;
        const participantAmt = parseInt(group.participantAmt);

        //console.log("group id: " + groupid);
        //console.log("option type: " + type);
    

        /**For Group id, get room id and no. of participant of selected type. 
            The room should have status 0 (not started). Order by startdatetime
        */

        try {
                //console.log("calling api");

                let response = await axios.get(`https://api.reso.chat/room/checkRoom?group=${groupid}&type=${type}`, {
                        headers: {
                        "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
                    }
                });
                
                //console.log("response: " + JSON.stringify(response.data));
                response = response.data;
    
            // Loop thru the rooms and check
            for(let i=0;i<response.length;i++) {
                    let id = response[i].roomId;
                    let participantCount = parseInt(response[i].participantCount);
                    let identity = 0;

                    // Check the identity of the selected type
                    group.openingQuestion.choices.forEach(function(choice) {
                        if(type === choice.label) {
                            identity = parseInt(choice.identity);
                        }
                    });
                    //console.log("Identity : " + identity);

                    /* Check if no. of participants are less than allowed of 
                        selected type. */
                    let allowedParticipant = identity;

                    //console.log("allowedParticipant: " + allowedParticipant);

                    if(participantCount < allowedParticipant) {
                        console.log("Got the room id: " + id);
                        payload = {
                            group,
                            roomId: id,
                            optionType: type
                        };
                        token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});
                        history.push(`/waiting?auth=${token}`);
                        return null;
                    }
            }
            // Create room
            console.log("Creating Room");
            let newRoom = await axios.post("https://api.reso.chat/room", { group: groupid }, {
                headers: {
                    "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
                }
            });
            payload = {
                group,
                roomId: newRoom.data._id,
                optionType: type
            };
            token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});
            history.push(`/waiting?auth=${token}`);
            return null;

        }
        catch(e) {
        }
    }


        /*Function to find the Room id. All type1 in one room,
            type2 in another, etc
        */
        const getRoomPerType = async() => {
            if(!optionValue.length) {
                alert(t('main.plschooseoption'));
                return;
            }
            console.log("inside get room per type. optionValue: " + optionValue);
            setLoading(true);
    
            let payload, token;
            const groupid = group._id;
            const type = optionValue;
            const participantAmt = parseInt(group.participantAmt);
    
            console.log("group id: " + groupid);
            console.log("option type: " + type);
        
    
            /**For Group id, get room id and no. of participant of selected type. 
                The room should have status 0 (not started). Order by startdatetime
            */
    
            try {
                    let response = await axios.get(`https://api.reso.chat/room/checkRoom?group=${groupid}&distinct=true`, {
                            headers: {
                            "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
                        }
                    });
                    
//                    console.log("response: " + JSON.stringify(response.data));
                    response = response.data;
        

                // Loop thru the rooms and check
                for(let i=0;i<response.length;i++) {
                        let id = response[i].roomId;
    
                        /*Loop through all Participants
                        Check if the selected type is equal to the 
                         type of first participant. If equal then let
                         participant join this room. Else check the next room
                        */
                        let participants = response[i].participants;

                        if(type === participants[0].optionType) {
    
  //                          console.log("Got the room id: " + id);
                            payload = {
                                group,
                                roomId: id,
                                optionType: type
                            };
                            token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});
                            history.push(`/waiting?auth=${token}`);
                            return null;
                        }
                }
                // Create room
    //            console.log("Creating Room");
                let newRoom = await axios.post("https://api.reso.chat/room", { group: groupid }, {
                    headers: {
                        "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
                    }
                });
                payload = {
                    group,
                    roomId: newRoom.data._id,
                    optionType: type
                };
                token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});
                history.push(`/waiting?auth=${token}`);
                return null;
    
            }
            catch(e) {
            }
        }
    
    return (
        <div className="WelcomeScreen">
            <div className="WelcomeScreen__header">{t('main.welcome2rechat')}</div>
            <img src={Bot} alt="Bot"/>
            <div className="text">
                {group.placement_instruction}
            </div>
            <div className="WelcomeScreen__question">
                {group.openingQuestion.description}
            </div>
            <div className="options">
                {
                    group.openingQuestion.choices.map((choice, index) => (
                        choice.label !== "" ?
                            <div key={index} className="option__wrapper" >
                                <input  type="radio" name="option" value={choice.label} 
                                    onChange={onChangeValue}/>
                                <label className="option">{choice.label}</label><br />
                            </div>
                        : null                        
                    ))
                }
            </div>
            <button className="findBtn" onClick={group.openingQuestion.individualRoom === 1 ? getRoomPerType : getRoom}>{t('main.findroom')}</button><br />
            {    
                loading && (
                    <BarLoader />
                )
            }
        </div>
    )
}

export default WelcomeScreen
