import React, {  useState } from 'react';
import Modal from 'react-modal';
import './QuestionPollPopUp.css';
import {CgClose} from 'react-icons/cg';
import { QuestionPollStyles } from '../../DialogBoxStyles';


Modal.setAppElement('#root')

function QuestionPollPopUp({ pollData, choiceSubmit, handleVote, voteEnabled }) {
    const [showdialog,setShowdialog] = useState(false);
    const [radio,setRadio]=useState();
    const [errors,setErrors]=useState({});

      const validateInfo = () =>{
          let submitErrors = {}
          if(!radio){
            submitErrors.radio = `Please select one of the option!` 
          }
          return submitErrors
      }

      const handleSubmit = (e) =>{
         e.preventDefault();
          setErrors(validateInfo())
          if(Object.keys(validateInfo()).length > 0){
              return;
          }else{
            setShowdialog(false);
            handleVote(radio);
          }
      }

      console.log(errors);
      console.log("radioValue",radio);

    return (
        <div> 
            <button className="chatQuestionPoll__btn" disabled={choiceSubmit || !voteEnabled} onClick={()=> setShowdialog(true)}>{
              choiceSubmit ? 'Voted' : 'Vote'
            }</button>

            <Modal isOpen={showdialog}  onRequestClose={() => setShowdialog (false) } style={QuestionPollStyles}>
               <div className="QuestionPollPopUp">
                   <div className=" QuestionPollPopUp__title_wrapper">
                   <h3 className="chatQuestionPoll__questionTitle QuestionPollPopUp__title">{pollData?.question}</h3>
                    <CgClose className="chatQuestionPoll__icon" onClick={()=> setShowdialog(false)}/>
                   </div>

                   {pollData.map((choice,index) =>(
                    <div className="QuestionPollPopUp__choice_wrapper" key={index}>
                   <input type="radio" className="QuestionPollPopUp__radio" checked={radio === `${choice}`} value={choice} onChange={(e)=>setRadio(e.target.value)}/>
                   <h3 className="chatQuestionPoll__choice_text">{choice} </h3>
                   </div>
                   ))}

                    <div className="QuestionPollPopUp_btnWrapper">
                        {errors.radio && <h6 className="QuestionPollPopUp__error">{errors.radio}</h6>}
                    <button className="chatQuestionPoll__btn QuestionPollPopUp_btn" onClick={handleSubmit}>Submit</button>
                    </div>
               </div>
            </Modal>
        </div>
    )
}

export default QuestionPollPopUp;