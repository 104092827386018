import React, {  useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../DialogBoxStyles';
import './PopUp.css';

Modal.setAppElement('#root')
function PopUp({socket, throwBotMessage, color, showdialog , setShowdialog}) {
    const [input ,setInput]=useState()

      const handleSubmit = (e) =>{
       e.preventDefault();
       socket.emit("dm", {
         text: input,
         to: color
       });
       setShowdialog (false);
       throwBotMessage("Message sent successfully.")
       console.log(input);
      }
    
    return (
        <div>
            <Modal isOpen={showdialog}  onRequestClose={() => setShowdialog (false) } style={customStyles}>
                <div style={{borderRadius:"10px"}} className="modal_wrapper">
                <h3>Send Message </h3>
               <input className="popUp_input" value={input} onChange={(e)=>setInput(e.target.value)}  type="text" placeholder="Send Message"/>

               <div className="modal_btns">
               <button className="modal_red_btn" onClick={handleSubmit}>Submit</button> 
               <button className="modal_blue_btn" onClick={()=> { setShowdialog (false) }}>Cancel</button>
               </div>
               </div>
            </Modal>
        </div>
    )
}

export default PopUp;
