export const QuestionPollStyles = {
    overlay:{
        
    },
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '40%',
      maxWidth:"auto",
      minHeight: '30%',
      maxHeight: 'auto',
      transform: 'translate(-50%, -10%)',
      borderRadius:"10px",
      boxShadow:"0px 0px 20px #0000001e",
    }
  };

 export const customStyles = {
    overlay:{
        
    },
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '40%',
      maxWidth:"80%",
      transform: 'translate(-50%, -10%)',
      borderRadius:"10px",
      boxShadow:"0px 0px 20px #0000001e",
    }
  };