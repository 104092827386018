import React, {  useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../DialogBoxStyles';
import axios from 'axios';
import {CgClose} from 'react-icons/cg';

Modal.setAppElement('#root')

function SorryPopUp({ visible, close , prefixTimeoutCode , REACT_APP_BOT_TOKEN, waitTimeOutInstructions}) {
    const [prefixCode,setPrefixCode] = useState("");
    const [copied , setCopied] = useState(false)
    const { t } = useTranslation();

    console.log("waitTimeOutInstructions>>>>>",waitTimeOutInstructions);

    useEffect(()=>{
        const handleTimeoutcode = async ()=>{
            const num = 5000000000000000;
            let prefixRandom;
            if(prefixTimeoutCode && prefixTimeoutCode !== undefined){
               prefixRandom =  `${prefixTimeoutCode}${Math.trunc(Math.random() * num)}`
            }else{
               prefixRandom =  Math.trunc(Math.random() * num)
            }
            // console.log("prefixRandom>>>",prefixRandom);
            setPrefixCode(prefixRandom)
             await axios.put(`https://api.reso.chat/participant?id=${sessionStorage.getItem("participantId")}`, { receiptCode: prefixRandom }, {
             headers: {
               "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
             }
           });
          }
        handleTimeoutcode()
    },[prefixTimeoutCode , REACT_APP_BOT_TOKEN])

    const handleSubmit = async(e) =>{
        e.preventDefault();
        try {
         await navigator.clipboard.writeText(prefixCode);
         setCopied(true)
          } catch (err) {
          console.error('Failed to copy: ', err);
          }
       }
    

    return (
        <div>
            <Modal isOpen={visible}  onRequestClose={close} style={customStyles}>
                <div className="finished_modal_wrapper">
                
              <CgClose className="chatQuestionPoll__icon sorryPopup__closeIcon" onClick={close}/>
               
               <h4 className="finished_modal_number">{prefixCode}</h4>

               <h5 className="finished_modal_title"> {waitTimeOutInstructions}</h5>
               
               <div className="finished_modal_btns">
               <button className="finished_modal_red_btn" onClick={handleSubmit}>{t('main.copy')}</button>
               </div>

               {copied && <h5 className="copied_message">{t('main.copied')}</h5>}
               </div>
            </Modal>
        </div>
    )
}

export default SorryPopUp;
