import axios from "axios";
import React, { useState } from "react";
import "./ChatQuestionPoll.css";
import QuestionPollPopUp from "./QuestionPollPopUp/QuestionPollPopUp";

const REACT_APP_BOT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

function ChatQuestionPoll({ socket, choices, description, setVoted, aggResp, room, qid, voteEnabled }) {
    socket.on("fetchResponses", () => {
      if(!aggResp || !choiceSubmit) return;
      axios.get(`https://api.reso.chat/response/stats?question=${qid}&room=${room}`, {
        headers: {
          "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
      }
      }).then(data => {
        setChoiceList(data.data);
      }).catch(e => console.log(e));
    })

  const [choiceList, setChoiceList] = useState(choices.map(c => { return {choice: c, percentage: 0} }));
  
  const [choiceSubmit,setChoiceSubmit]=useState(false);
  const [selected, setSelected] = useState("");

  const handleVote = async (choice) => {
    setChoiceSubmit(true);
    setSelected(choice);    
    await setVoted(choice);
  }

  return (
    <div className="chatQuestionPoll">
      <h3 className="chatQuestionPoll__questionTitle">{description}</h3>

      <div className="chatQuestionPoll__choices_wrapper">
        
        {choiceList.map((choice,index)=>(
           <div className="chatQuestionPoll__choice_wrapper" key={index}>
              <h5 className="chatQuestionPoll__choiceTitle" style={{fontWeight: choice.choice === selected ? 'bold' : 'normal'}}>{choice.choice}</h5> 

               {
                 aggResp === 1 ? (
                  <div className="chatQuestionPoll_progressBar_wrapper">
                    {
                    choiceSubmit ?(
                        <div className="chatQuestionPoll_progressBar" style={{width:`${choice.percentage}%`,opacity:1}}></div> 
                    )
                    :
                    (
                      <div className="chatQuestionPoll_progressBar"></div>
                    )
                  } 
                </div>
                 ) : <></>
               }
            </div>
        ))}
      </div>

             <QuestionPollPopUp
             pollData={choices}
             choiceSubmit={choiceSubmit}
             handleVote={handleVote}
             voteEnabled={voteEnabled} />
    </div>
  );
}

export default ChatQuestionPoll;
