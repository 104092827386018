import React from 'react';
import './pageNotFound.css';
import PageNotFoundSvg from './svg/404.svg'
import SadSvg from './svg/sad.svg'


const PageNotFound = () => {

    return (
        <div className="PageNotFound">
            <div className="PageNotFound_wrapper">
            <img className="PageNotFound_svg" src={PageNotFoundSvg} alt="404"/>
               <div className="PageNotFound_title">
               <img className="sad_svg" src={SadSvg} alt="sad"/>
               <h2>Chat Group Not Found</h2>
               </div>
        <a href="https://reso.chat"><button className="PageNotFound_btn">Go to Home</button></a>
             </div>
        </div>
    )
    
}

export default PageNotFound;
