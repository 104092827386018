import React, { Component, useEffect } from 'react';
import PageNotFound from '../PageNotFound';
import Loading from '../Loading';
import axios from 'axios';
import WelcomeScreen from '../WelcomeScreen';
import { withTranslation } from 'react-i18next';

const jwt = require('jsonwebtoken');

const JWT_KEY = "vceruieuhfc87chdsrc87hu&*@YR*Y73tfyhi8";

const REACT_APP_BOT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

let groupId, authToken;

class findGroup extends Component {
    constructor(props) {
      super(props);
  
      this.state = { loading: true };
  
      const params = new URLSearchParams(props.location.search);
      groupId = params.get("group");
      authToken = params.get("auth");
    }
    
    async componentDidMount() {
      try {
        if(authToken) {
          try {
            let data = jwt.verify(authToken, JWT_KEY);
            if(data.moderator === true) {
              this.props.history.push(`/chat?auth=${authToken}`);
            }
          }
          catch(e) {}
        }
        if(groupId && groupId.length >= 12) {
            let group = await axios.get(`https://api.reso.chat/group/${groupId}`, {
                headers: {
                    "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
                }
            });
            group = group.data;
            
            this.setState({group, loading: false});
        }
      }
      catch(e) {
        this.setState({loading: false})
      }
    }
  
    render() {
      if(!groupId || groupId.length < 12) {
        return(
          <PageNotFound />
        );
      }
      else {

        // const { i18n } = this.props;
        // if(this.state.group !== undefined && this.state.group.language === 2 && i18n.language === 'en') {
        //   i18n.changeLanguage("chi");
        // } 
        
        // if(this.state.group !== undefined && this.state.group.language === 1 && i18n.language === 'chi') {
        //   i18n.changeLanguage("en");
        // }
  
        return(
            <>
                {
                    this.state.loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                        {
                            this.state.group ?
                            (
                                <>
                                    {

                                        this.state.group.openingQuestion.description &&
                                        this.state.group.openingQuestion.choices.length 
                                        //&& this.state.group.openingQuestion.choices[0].identity 
                                        ?
                                        (
                                            <WelcomeScreen history={this.props.history} group={this.state.group} />
                                        )
                                        :
                                        (
                                            <FindRoom history={this.props.history} group={this.state.group} />
                                        )
                                    }
                                </>
                            )
                            :
                            (
                                <PageNotFound />
                            )
                        }
                        </>
                    )
                }
            </>
        )
      }
    }
  
}

function FindRoom({group, history}) {
  let payload, token;
  useEffect(() => {
    async function getRooms() {
      let rooms = await axios.get(`https://api.reso.chat/room/checkRoom?group=${group._id}`, {
        headers: {
          "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
        }
      });
      rooms = rooms.data;
      console.log(rooms);
      for(let i=0; i<rooms.length; i++) {
          if(parseInt(rooms[i].participantCount) < parseInt(group.participantAmt)) {
            payload = { group, roomId: rooms[i].roomId };
            token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});
            history.push(`/waiting?auth=${token}`);
            return null;
          }
      }
      // Create Room
      console.log("Creating Room");
      let newRoom = await axios.post("https://api.reso.chat/room", { group: group._id }, {
          headers: {
              "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
          }
      });
      payload = { group, roomId: newRoom.data._id };
      token = jwt.sign(payload, JWT_KEY, {expiresIn: "1d"});

      history.push(`/waiting?auth=${token}`);
      return null;
    }
    getRooms();
  })
  return (<Loading />);
}

export default withTranslation()(findGroup);