import React, { useEffect } from 'react'

function CustomHtml({welcome_message}) {
    useEffect(()=>{
        const htmlToDom = async()=>{
            document.querySelector("#custom_html").insertAdjacentHTML("afterbegin",`${welcome_message}`)
        }
        htmlToDom();
       },[welcome_message])
    return (
        <div id="custom_html" className="CustomHtml__wrapper">
                          
        </div>
    )
}

export default CustomHtml;


