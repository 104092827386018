import React, { useEffect, useState } from 'react';
import './loading.css';
import HashLoader from "react-spinners/HashLoader";

const Loading = () => {
    const [loading,setLoading] = useState(true)
    
    // useEffect(()=>{
    //     setLoading(true)
    //    setInterval(()=>{
    //     setLoading(false)
    //    },5000)
    // },[])

    return (
        <div className="loading" >
            <div className="loading_header">ReChat</div>
            <div className="loading_wrapper">
            <HashLoader
              color={"#F37A24"} 
              loading={loading}  
              size={120}/>
              <h3 className="loading_title">Getting Ready To Provide You Best Chat Experience...</h3>
            </div>    
        </div>
    )
}

export default Loading;
