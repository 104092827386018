import './ThreadMessage.css';
import React, {  useState } from 'react'
import Bot from '../../assets/images/bot.png';
import Moderator from '../../assets/images/admin.png';
import { motion } from 'framer-motion';
import PopUp from './PopUp/PopUp';
import blockImg from '../../assets/images/block.svg';
import muteImg from '../../assets/images/mute.svg';
import sendSvg from '../../assets/images/send.svg';
import cancelImg from '../../assets/images/cancel.svg';
import ChatQuestionPoll from '../ChatQuestionPoll/ChatQuestionPoll';

function ThreadMessage({
    direction,
    color,
    time,
    children,
    bot,
    video_link,
    image_link,
    question,
    moderator,
    socket,
    throwBotMessage,
    nickname,
    isMCQ,
    choices,
    setVoted,
    aggResp,
    room,
    qid,
    voteEnabled
}) {
    const [clicked,setClicked] = useState(false);
    const [showdialog,setShowdialog] = useState(false);

    const fadeInLeft = {
        hidden : { opacity:0,y:-100},
        visible : {opacity:1,y:0}
    }

    const handleClick=()=>{
        setClicked(false)
        setShowdialog(true)
    }
    const handleBlock = () => {
        setClicked(false);
        socket.emit("block", { color });
        throwBotMessage("Selected Participant has been blocked.")
    }

    const handleMute = () => {
        setClicked(false);
        socket.emit("mute", { color });
        let mutedList = JSON.parse(localStorage.getItem("mutedList")) || [];
        mutedList.push(color);
        mutedList = JSON.stringify(mutedList);
        localStorage.setItem("mutedList", mutedList);
        throwBotMessage("Selected Participant has been muted.")
    }

    const handleUnmute = () => {
        setClicked(false);
        socket.emit("unmute", { color });
        let mutedList = JSON.parse(localStorage.getItem("mutedList")) || [];
        mutedList = mutedList.filter(c => c !== color);
        mutedList = JSON.stringify(mutedList);
        localStorage.setItem("mutedList", mutedList);
        throwBotMessage("Selected Participant has been unmuted.")
    }

    if(direction==='left' && !bot && !question)
    {
        return (
            <div className="ThreadMessage" >
                {color === "moderator" ?
                <img src={Moderator} alt="moderator" width="50px" height="50px" style={{marginLeft:"10px"}}/>
                :
                <div onClick={() => {if(moderator) setClicked(!clicked)}} className="profile_pic" style={{backgroundColor: color}} title={nickname} />
                }
                <div className="message">
                    <div className="message_nickname">{nickname}</div>
                    <div className="message_body">
                        {children}
                    
                    </div>
                    <div className="message_time">{time}</div>
                </div>
            
            {
                clicked ? (
                    <>
                    <motion.div
                      whileTap={{scale:0.9}}
                      variants={fadeInLeft}
                      initial="hidden"
                      animate='visible'
                      transition={{duration:0.4}}  className='dropdown_menu_active'>
                    <ul className="dropdown_menu_ul">
                             <div className="dropdown_links" onClick={handleClick}>
                             <img src={sendSvg} className="dropdown_svg" alt="svg"/><li className="dropdown_title">Send Message</li>
                             </div>   
                             <div  className="dropdown_links" onClick={handleBlock}>
                              <img src={blockImg} className="dropdown_svg" alt="svg"/><li className="dropdown_title">Block User</li>
                            </div>
                             {
                                 !(localStorage.getItem("mutedList") && localStorage.getItem("mutedList").includes(color))?
                                 <div  className="dropdown_links" onClick={handleMute}>
                                    <img src={muteImg} className="dropdown_svg" alt="svg"/><li className="dropdown_title">Mute User</li>
                                 </div>
                                 :
                                 <div  className="dropdown_links" onClick={handleUnmute}>
                                    <img src={muteImg} className="dropdown_svg" alt="svg"/><li className="dropdown_title">Unmute User</li>
                                 </div>
                             }  

                            <div className="dropdown_cancel">
                            <img src={cancelImg} className="dropdown_cancel_svg" alt="svg" onClick={()=>setClicked(false)}/>
                             </div>  
                    </ul>
                   </motion.div>
                    </>
                ):( 
                    <>
                    </>
                )
            }

        {
           showdialog ? (
             <PopUp showdialog={showdialog}  setShowdialog={setShowdialog} socket={socket} throwBotMessage={throwBotMessage} color={color}/>
           ):(
             <>
             </>
           )
        }
            </div>
        )    
    }
    else if(direction==='right')
    {
        return (
            <div className="ThreadMessage" style={{alignSelf:"flex-end"}}>
                <div className="message">
                    <div className="message_nickname">{nickname}</div>
                    <div className="message_body">{children}</div>
                    <div className="message_time">{time}</div>
                </div>
                {color === "moderator" ?
                <img src={Moderator} alt="moderator" width="50px" height="50px" style={{marginLeft:"10px"}}/>
                :
                <div className="profile_pic" style={{backgroundColor: color, marginLeft:"10px"}} title={nickname} />
                }
            </div>
        )
    }
    else if(bot){
        return (
            <div className="botMessage">
                <img src={Bot} alt="bot" width="50px" height="50px"/>
                <div className="ThreadMessage">
                    <div className="message">
                    {children}
                        </div>
                    <div className="message_time">{time}</div>
                </div>
            </div>
        )
    }

    else if(question && !isMCQ){
        return (
            <>
                <div className="questionMessage">
                    <div className="ThreadQuestion">
                        <div className="message">
                        {children.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                            </div><br />
                        <div className="question_time">{time}</div>
                    </div>
                </div>
                {video_link && 
                    <video className="question_media" width="320" height="240" controls>
                        <source src={video_link} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
                {image_link && 
                    <img alt="question_image" className="question_media" width="320" height="240" src={image_link} />
                }
            </>
        )
    }

    else if(isMCQ && choices && choices.length) {
        return (
           <div className="questionMessage">
                <ChatQuestionPoll
                socket={socket}
                room={room}
                qid={qid}
                choices={choices}
                description={children}
                setVoted={setVoted}
                aggResp={aggResp}
                voteEnabled={voteEnabled} />
           </div>
        )
    }
}

export default ThreadMessage
