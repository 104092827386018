import axios from 'axios';
import React, {  useEffect, useState } from 'react';
import Modal from 'react-modal';
import './PopUp.css';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../DialogBoxStyles';

const REACT_APP_BOT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

Modal.setAppElement('#root')
function ChatendPopUp({ endInstruction , prefixCompletionCode }) {
    const [showdialog, setShowdialog] = useState(true);
    const [prefixCode,setPrefixCode]= useState(0)
    const [copied , setCopied] = useState(false)
    const { t } = useTranslation();

    // console.log("prefixCompletionCode",prefixCompletionCode);
      useEffect(()=>{
        const handleRandomNum = async ()=>{
          console.log("popup triggered");
          const num = 5000000000000000;
          let prefixRandom =  `${prefixCompletionCode}${Math.trunc(Math.random() * num)}`
          // console.log("prefixRandom>>>",prefixRandom);
          setPrefixCode(prefixRandom)
        const res =   await axios.put(`https://api.reso.chat/participant?id=${sessionStorage.getItem("participantId")}`, { receiptCode: prefixRandom }, {
           headers: {
             "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
           }
         });
         console.log("prefixRes",res);
        }
        handleRandomNum();
      },[prefixCompletionCode])
      

      const handleSubmit = async(e) =>{
       e.preventDefault();
       try {
        await navigator.clipboard.writeText(prefixCode);
        setCopied(true)
         } catch (err) {
         console.error('Failed to copy: ', err);
         }
      }

      // console.log(copied);
      // console.log("prefixCode>>>",prefixCode);
    
    return (
        <div> 
            <Modal isOpen={showdialog}  onRequestClose={() =>{
                                                                 setCopied(false)                                            
                                                              }} style={customStyles}>
                <div style={{borderRadius:"10px"}} className="modal_wrapper">
                      <h4 className="finished_modal_number">{prefixCode}</h4>
              <h5 className="finished_modal_title">{endInstruction}</h5>
               <div className="finished_modal_btns">
               <button className="finished_modal_red_btn" onClick={handleSubmit}>{t('main.copy')}</button>
               </div>
               {copied && <h5 className="copied_message">{t('main.copied')}</h5>}
               </div>
            </Modal>
        </div>
    )
}

export default ChatendPopUp;
