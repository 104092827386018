import "./ChatRoom.css";
import React, { Component } from "react";
import ThreadMessage from "../ThreadMessage";
import Smiley from "../../assets/images/Smiley.png";
import Send from "../../assets/images/Sending.png";
import Picker from "emoji-picker-react";
import socketIOClient from "socket.io-client";
import PageNotFound from "../PageNotFound";
import axios from "axios";
// import FirstPopUp from './FinishedPopUp/FirstPopUp';
import { withTranslation } from "react-i18next";
import ChatendPopUp from "./FinishedPopUp/ChatendPopUp";

const jwt = require("jsonwebtoken");

const JWT_KEY = "vceruieuhfc87chdsrc87hu&*@YR*Y73tfyhi8";

const socket = socketIOClient("https://api.reso.chat");

const REACT_APP_BOT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

let roomId, token, group, groupTimer, t;
let keyboardKey = false;

class ChatRoom extends Component {
  constructor(props) {
    super();

    this.state = {
      chosenEmoji: null,
      emojiOpen: false,
      inputText: "",
      messages: [],
      me: "",
      question: null,
      showFinCon: false,
      sendAllowed: true,
    };
    this.divToFocus = React.createRef();
    const params = new URLSearchParams(props.location.search);
    token = params.get("auth");
    t = props.t;
  }

  onEmojiClick(event, emojiObject) {
    this.setState({
      chosenEmoji: emojiObject,
      emojiOpen: false,
      inputText: this.state.inputText + emojiObject?.emoji,
    });
  }

  formatAMPM(timestamp) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  sendMessage() {
    if (this.state.inputText.length === 0 || !this.state.sendAllowed) {
      return;
    }
    const expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    if (this.state.inputText.match(regex)) {
      this.throwBotMessage(t("main.nopostingurl"));
      return;
    }

    if (group?.offensive_filter) {
      let flag = true,
        dict =
          group.language === 1
            ? require("../../assets/dictionaries/english.json")
            : require("../../assets/dictionaries/chinese.json");
      dict.forEach((word) => {
        if (this.state.inputText.toLowerCase().includes(word)) {
          flag = false;
          return;
        }
      });
      if (!flag) {
        this.throwBotMessage(t("main.nooffensivelang"));
        return;
      }
    }

    let data = {
      color: this.state.me,
      time: Date.now(),
      text: this.state.inputText,
      question: this.state.question,
      nickname: this.state.nickname,
    };
    socket.emit("chatMessage", data);

    let temp = this.state.messages;
    data.time = this.formatAMPM(data.time);
    temp.push(data);
    this.setState({ messages: temp, inputText: "" });
    this.scrollToBottom();
  }

  scrollToBottom = (event) => {
    // if(this.divToFocus.current){
    //     this.divToFocus.current.scrollIntoView({
    //        behavior: "smooth",
    //        block: "nearest"
    //     })
    // }
    const scrollElement = document.querySelector(".message_box");
    console.log("scrollHeight>>>>>", scrollElement.scrollHeight);
    scrollElement.scrollTo({
      top: scrollElement.scrollHeight,
    });
  };

  /*new thing added 28th june*/

  handleKeyDown = (e) => {
    const Key = e.key;
    console.log(Key);
    if (Key === "Enter") {
      keyboardKey = true;
    } else {
      keyboardKey = false;
    }
  };

  throwBotMessage(message) {
    let temp = this.state.messages;
    temp.push({
      time: this.formatAMPM(Date.now()),
      text: message,
      bot: true,
    });
    this.setState({ messages: temp, inputText: "" });
    this.scrollToBottom();
  }

  throwQuestion(question) {
    let temp = this.state.messages;
    let isMCQ = false;
    let choices = [];

    if (question.choice1 && question.choice1.length) {
      choices.push(question.choice1);
      isMCQ = true;
    }
    if (question.choice2 && question.choice2.length) {
      choices.push(question.choice2);
      isMCQ = true;
    }
    if (question.choice3 && question.choice3.length) {
      choices.push(question.choice3);
      isMCQ = true;
    }
    if (question.choice4 && question.choice4.length) {
      choices.push(question.choice4);
      isMCQ = true;
    }
    if (question.choice5 && question.choice5.length) {
      choices.push(question.choice5);
      isMCQ = true;
    }
    temp.push({
      text: question.description,
      question: true,
      video_link:
        question.mediaUrl && question.mediaUrl.includes("amazonaws.com")
          ? question.mediaUrl
          : null,
      image_link:
        question.mediaUrl && question.mediaUrl.includes("constant.reso.chat")
          ? question.mediaUrl
          : null,
      time: this.formatAMPM(Date.now()),
      isMCQ,
      choices,
      qid: question._id,
    });
    this.setState({ messages: temp, inputText: "", isMCQ });
    this.scrollToBottom();
  }

  async componentDidMount() {
    let payload;
    try {
      payload = jwt.verify(token, JWT_KEY);
    } catch (e) {
      console.log(e);
      this.props.history.push("/error");
      return;
    }
    roomId = payload.roomId;
    group = payload.group;

    if (localStorage.getItem("muted") === roomId) {
      alert("You have been muted by the moderator. You cannot rejoin.");
      this.props.history.push("/");
    }

    if (localStorage.getItem("blocked") === roomId) {
      alert("You have been blocked by the moderator. You cannot rejoin.");
      this.props.history.push("/");
    }

    localStorage.setItem("chatroomtoken", token);
    localStorage.setItem("groupId", group._id);

    axios
      .get(`https://api.reso.chat/room/${roomId}`, {
        headers: {
          Authorization: `Bearer ${REACT_APP_BOT_TOKEN}`,
        },
      })
      .then((checkRoom) => {
        if (checkRoom?.data?.room?.status === 2) {
          localStorage.clear();
          alert("This chat is already ended.");
          this.props.history.push("/");
        }

        if (payload.group.chatEndMode === 1) {
          // Set group timer
          this.setState({
            remainingTime: parseInt(
              payload.group.expTime -
                (new Date(Date.now()) -
                  new Date(checkRoom.data.room.startDateTime)) /
                  60000
            ),
          });
          groupTimer = setInterval(() => {
            this.setState({ remainingTime: this.state.remainingTime - 1 });
            if (
              this.state.remainingTime === 1 &&
              payload.group.expWarning === 1
            ) {
              this.throwBotMessage(t("main.chatexpirywarn"));
            }
            if (this.state.remainingTime === 0) {
              this.endChat();
            }
          }, 60000);
        }
      });

    document.addEventListener(
      "keydown",
      this.handleKeyDown
    ); /*new thing added 28th june*/

    // Connect to Bot Server
    if (group.transcriptUrl) {
      socket.emit("initBots", {
        roomId,
        transcriptUrl: group.transcriptUrl,
      });
    }

    this.setState({ chatEndMode: payload.group.chatEndMode });
    if (payload.group.chatEndMode === 0) {
      let questions = payload.group.questions;
      questions = questions.filter((question) => question.quesType === 1);
      this.setState({ remainingQues: questions.length });
      socket.on("finishCon", () => {
        this.endChat();
      });
    }

    // Set Participant code and join socket
    if (!payload.moderator || payload.moderator === null) {
      this.setState({ me: payload.colorCode, nickname: payload.nickname });
    } else {
      this.setState({ me: "moderator", nickname: "Moderator" });
    }
    socket.emit("joinRoom", payload);

    // Get messages from database
    let responses = await axios.get(
      `https://api.reso.chat/message?room=${roomId}`,
      {
        headers: {
          Authorization: `Bearer ${REACT_APP_BOT_TOKEN}`,
        },
      }
    );
    responses = responses.data.messages;
    let messages = this.state.messages;
    responses.forEach((message) => {
      messages.push({
        text: message.description,
        color: message.participantCode,
        time: this.formatAMPM(message.timestamp),
      });
    });
    this.setState({ messages });
    console.log(this.state.messages);

    socket.on("chatMessage", (data) => {
      let temp = this.state.messages;
      data.time = this.formatAMPM(data.time);
      temp.push(data);
      this.setState({ messages: temp });
      this.scrollToBottom();
    });

    socket.on("question", (data) => {
      if (!data) return;
      this.setState({ question: data._id, quesType: data.quesType });

      if (this.state.chatEndMode === 0 && data.quesType === 1) {
        this.setState({ remainingQues: data.remaining });
      }
      if (data.quesType === 1) {
        this.throwQuestion(data);
      } else {
        this.throwBotMessage(data.description);
      }
    });

    socket.on("dm", (data) => {
      if (data.to !== this.state.me) return;

      let temp = this.state.messages;
      data.time = this.formatAMPM(Date.now());
      data.color = "moderator";
      temp.push(data);
      this.setState({ messages: temp });
      this.scrollToBottom();
    });

    socket.on("block", (data) => {
      if (data.color !== this.state.me) return;
      else {
        socket.disconnect();
        this.setState({ sendAllowed: false });
        localStorage.setItem("blocked", roomId);
        this.throwBotMessage(t("main.youareblocked"));
      }
    });

    socket.on("mute", (data) => {
      if (data.color !== this.state.me) return;
      else {
        this.setState({ sendAllowed: false });
        localStorage.setItem("muted", roomId);
        this.throwBotMessage(t("main.youaremuted"));
      }
    });

    socket.on("unmute", (data) => {
      if (data.color !== this.state.me) return;
      else {
        this.setState({ sendAllowed: true });
        localStorage.removeItem("muted");
        this.throwBotMessage(t("main.youareunmuted"));
      }
    });

    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        this.sendMessage();
      }
    });
  }

  componentWillUnmount() {
    socket.emit("leaveRoom", roomId);
    socket.disconnect();
    document.removeEventListener(
      "keydown",
      this.handleKeyDown
    ); /*new thing added 28th june*/
  }

  endChat() {
    this.setState({ showFinCon: true });
    try {
      clearInterval(groupTimer);
      this.setState({ sendAllowed: false });
      this.throwBotMessage(t("main.chatended"));
      localStorage.clear();
      socket.disconnect();
      console.log("Ending Room");
      axios.put(
        `https://api.reso.chat/room?id=${roomId}`,
        { status: 2, endDateTime: Date.now() },
        {
          headers: {
            Authorization: `Bearer ${REACT_APP_BOT_TOKEN}`,
          },
        }
      );
    } catch (e) {}
  }

  render() {
    const { t } = this.props;

    console.log("group", group);

    if (!roomId || roomId.length < 12) {
      return <PageNotFound />;
    } else {
      return (
        <div className="ChatRoom">
          <div className="ChatRoom__top">
            <div className="User_bubbles">
              <div
                className="participant_circle"
                style={{ backgroundColor: this.state.me }}
              >
                {t("main.me")}
              </div>
            </div>
            <div className="top_text">
              <div className="thin_text">{group.public_name}</div>
              {this.state.chatEndMode === 0 ? (
                <div className="bold_text">
                  {t("main.thereare")}{" "}
                  <span id="pink">
                    {this.state.remainingQues} {t("main.questions")}
                  </span>{" "}
                  {t("main.remaining")}.
                </div>
              ) : (
                <div className="bold_text">
                  {t("main.wegot")}{" "}
                  <span id="pink">
                    {this.state.remainingTime} {t("main.mins")}
                  </span>{" "}
                  {group?.language === 1 && `${t("main.here")}`}{" "}
                </div>
              )}
            </div>
          </div>
          <div className="message_box">
            {this.state.messages.map((message) => {
              return (
                <ThreadMessage
                  socket={socket}
                  throwBotMessage={this.throwBotMessage.bind(this)}
                  question={message.question}
                  video_link={message.video_link}
                  image_link={message.image_link}
                  bot={message.bot}
                  direction={message.color === this.state.me ? "right" : "left"}
                  color={message.color}
                  moderator={this.state.me === "moderator"}
                  nickname={message.nickname}
                  isMCQ={message.isMCQ}
                  choices={message.choices}
                  room={roomId}
                  qid={message.qid}
                  voteEnabled={
                    message.isMCQ &&
                    message.qid === this.state.question &&
                    this.state.sendAllowed
                  }
                  setVoted={async (choice) => {
                    const data = {
                      question: this.state.question,
                      color: this.state.me,
                      time: Date.now(),
                      choice,
                    };
                    try {
                      socket.emit("pollResponse", {
                        question: data.question,
                        room: roomId,
                        participantCode: data.color,
                        choice: data.choice,
                        timestamp: data.time,
                      });
                    } catch (e) {
                      console.log(e);
                    }

                    socket.emit("finishedAnswering");
                    this.setState({ quesType: 0 });
                  }}
                  aggResp={group.show_ag_response}
                  time={message.time}
                >
                  {message.text}
                </ThreadMessage>
              );
            })}
            {/* <div ref={this.divToFocus} /> */}
            {/* new thing added 28th june */}
            {keyboardKey ? (
              <div id="scroll" />
            ) : (
              <div id="scroll" style={{ marginTop: "90px" }}></div>
            )}
            {/* new thing added 28th june */}
          </div>

          <div className="input_bar">
            <input
              disabled={!this.state.sendAllowed}
              type="text"
              className="message_bar"
              placeholder={t("main.writemsg")}
              value={this.state.inputText}
              onChange={(e) => {
                this.setState({ inputText: e.target.value });
              }}
            />
            <div className="icons">
              <div className="icons_top">
                {this.state.emojiOpen && (
                  <Picker
                    pickerStyle={{
                      position: "absolute",
                      bottom: "100px",
                      right: "6vw",
                    }}
                    onEmojiClick={this.onEmojiClick.bind(this)}
                  />
                )}
                <img
                  src={Smiley}
                  className="chat_icon"
                  alt="Emoji"
                  width="35px"
                  onClick={() => {
                    this.setState({ emojiOpen: !this.state.emojiOpen });
                  }}
                />
                <img
                  src={Send}
                  className="chat_icon"
                  alt="Send Message"
                  width="30px"
                  onClick={this.sendMessage.bind(this)}
                />
              </div>

              <div>
                {this.state.quesType === 1 &&
                  this.state.me !== "moderator" &&
                  !this.state.isMCQ && (
                    <div
                      className="answered"
                      onClick={() => {
                        socket.emit("finishedAnswering");
                        this.setState({ quesType: 0 });
                      }}
                    >
                      {t("main.fanswering")}
                    </div>
                  )}
                {this.state.showFinCon && this.state.me !== "moderator" && (
                  <ChatendPopUp
                    endInstruction={group?.end_instruction}
                    prefixCompletionCode={group?.prefixCompletionCode}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(ChatRoom);
