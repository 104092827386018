import React, { Component, useEffect, useState } from "react";
import "./App.css";
import WaitingRoom from "./components/WaitingRoom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ChatRoom from "./components/ChatRoom";
import PageNotFound from "./components/PageNotFound";
import FindGroup from "./components/FindGroup";
import { useTranslation } from "react-i18next";
import axios from "axios";

const REACT_APP_BOT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

function App() {
  const [startChat, setStartChat] = useState(false);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            component={({ location, history }) => {
              const chatroomtoken = localStorage.getItem("chatroomtoken");
              const storedGroupId = localStorage.getItem("groupId");
              const requestedGroupId = new URLSearchParams(location.search).get(
                "group"
              );

              if (
                chatroomtoken &&
                requestedGroupId &&
                storedGroupId === requestedGroupId
              ) {
                return <Redirect to={`/chat?auth=${chatroomtoken}`} />;
              }

              let auth = new URLSearchParams(location.search).get("auth");
              if (startChat || auth)
                return <FindGroup location={location} history={history} />;
              else
                return (
                  <StartChat
                    start={() => setStartChat(true)}
                    location={location}
                  />
                );
            }}
          />
          <Route path="/chat" exact component={ChatRoom} />
          <Route path="/waiting" exact component={WaitingRoom} />
          <Route exact component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
}

function StartChat({ start, location }) {
  const [nickname, setNickname] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const { t, i18n } = useTranslation();

  let groupId;
  const params = new URLSearchParams(location.search);
  groupId = params.get("group");

  useEffect(() => {
    const fetchGroupData = async () => {
      if (groupId && groupId.length >= 12) {
        const groupRes = await axios.get(
          `https://api.reso.chat/group/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${REACT_APP_BOT_TOKEN}`,
            },
          }
        );
        const group = groupRes.data;
        // console.log("groupRes",group);

        if (
          group !== undefined &&
          group.language === 2 &&
          i18n.language === "en"
        ) {
          i18n.changeLanguage("chi");
        }

        if (
          group !== undefined &&
          group.language === 1 &&
          i18n.language === "chi"
        ) {
          i18n.changeLanguage("en");
        }
      }
    };
    fetchGroupData();
  }, [groupId, i18n]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!nickname || !nickname.length) {
      setShowWarning(true);
    } else {
      sessionStorage.setItem("nickname", nickname);
      start();
    }
  };

  return (
    <div className="start_chat">
      <div className="loading_header">ReChat</div>
      <div className="Homepage__bgbox1 hide_on_mobile" />
      <div className="Homepage__bgbox2 hide_on_mobile" />
      <div className="start_chat_wrapper">
        <div className="form__input_wrapper">
          <label className="form__input_label">{t("main.nickname")}</label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            className="start__chat-input"
            placeholder={t("main.choosenickname")}
          />
        </div>

        {showWarning && (
          <p className="form_dropdown_error">
            {t("main.pleasechoosenickname")}
          </p>
        )}

        <button className="start_chat_btn" onClick={handleSubmit}>
          {t("main.readytochat")}
        </button>
      </div>
    </div>
  );
}

export default App;
