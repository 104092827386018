import './WaitingRoom2.css';
import React from 'react';

function WaitingRoom2(props) {
    let gameUrl , mediaUrl;

    // console.log(gameUrl, mediaUrl);

    if(props.gameUrl !== undefined && props.gameUrl !== null) {
        gameUrl = props.gameUrl;
    }

    if(props.mediaUrl !== undefined && props.mediaUrl !== null) {
        mediaUrl = props.mediaUrl;
    }
    // console.log(gameUrl, mediaUrl);

    return (
            <div className="WaitingRoom2__body">
                {
                    gameUrl && 
                    <div className="body_item">
     <div dangerouslySetInnerHTML={{ __html: "<iframe src='" + gameUrl + "' class='game_iframe'/>"}} />
                </div>
                }

                {
                    mediaUrl && 
                    <div className="body_item">
                    <video className="video_iframe" controls>
                        <source src={mediaUrl} />
                        This video is not supported on your browser
                    </video>
                    </div>
                }
                
                
            </div>
    )
}

export default WaitingRoom2
