import './WaitingRoom.css';
import React, { Component } from 'react';
import Bot from '../../assets/images/bot.png';
import WaitingRoom2 from '../WaitingRoom2';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import { withTranslation } from 'react-i18next';
import CustomHtml from './CustomHtml';
import SorryPopUp from './SorryPopUp';
import {BsArrowDown} from 'react-icons/bs'

const jwt = require('jsonwebtoken');
const socket = socketIOClient("https://api.reso.chat");     

const JWT_KEY = "vceruieuhfc87chdsrc87hu&*@YR*Y73tfyhi8";

const REACT_APP_BOT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjozLCJpYXQiOjE2MTI0MzY4NTd9.NK9qS_3DYApqjWhDfPavCQ8FiAXROrUufYxTPoL1Yfw";

let myInterval, token, roomId, payload, gameUrl, mediaUrl , welcome_image, welcome_message, nickname,prefixTimeoutCode, waitTimeOutInstructions;

class WaitingRoom extends Component {

    constructor(props) {
        super(props);
        nickname = sessionStorage.getItem("nickname");
        this.state = {
            minutes: 0,
            seconds: 0,
            me: "",
            users: [],
            modalVisible: false,
            showScrollArrow:false,
        };
        const params = new URLSearchParams(props.location.search);
        token = params.get("auth");
    }

    async componentDidMount() {
        let possible = "0123456789ABCDEF", code = "#";
        for(let i=0;i<6;i++) {
            code += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        

        try {payload = jwt.verify(token, JWT_KEY);}
        catch(e) {this.props.history.push("/error"); return;}
        roomId = payload.roomId;

        if(payload.group.game !== undefined && payload.group.game !== null) {
            gameUrl = payload.group.game.gameUrl;
        }

        if(payload.group.video !== undefined && payload.group.video !== null) {
            mediaUrl = payload.group.video.mediaUrl;
        }
       
        console.log("payload",payload);

       if(gameUrl !== undefined || mediaUrl !== undefined) {
        this.setState({showScrollArrow : true});
        setTimeout(()=>{
            this.setState({showScrollArrow : false})
        },10000)
       }
       
    //    console.log(gameUrl !== undefined , mediaUrl !== undefined);
        // console.log("arrow",this.state.showScrollArrow);

        if(payload.group?.welcome_image && payload.group?.welcome_image !== undefined) {
            welcome_image = payload.group?.welcome_image;
        }else{
            welcome_image = false;
        }

        if(payload.group?.prefixTimeoutCode && payload.group?.prefixTimeoutCode !== undefined) {
            prefixTimeoutCode = payload.group?.prefixTimeoutCode;
        }

        //Get the Wait timeout Instructions if set

        console.log("payload>>>",payload);
        if(payload.group?.wait_timeout_instruction && payload.group?.wait_timeout_instruction !== undefined) {
            waitTimeOutInstructions = payload.group?.wait_timeout_instruction;
        } else {
            waitTimeOutInstructions = `${this.props.t('main.onlyoneparticipant')}`
        }

        // console.log(gameUrl , mediaUrl)

        if(payload.group?.welcome_message && payload.group?.welcome_message !== "<p></p>\n") {
            welcome_message = payload.group?.welcome_message;
        }else{
            welcome_message = false;
        }
        // console.log("welcome message",welcome_message);

        socket.emit("joinWaiting", {
            room: roomId,
            code,
            nickname,
            limit: payload.group.participantAmt
        });

        socket.on("joinedWaiting", (users) => {
            this.setState({me: code, users});
        });

        socket.on("startChat", () => {
            this.startChat();
        });

        axios.post("https://api.reso.chat/participant", {
            room: roomId,
            colorCode: code,
            optionType: payload.optionType,
            nickname
        }, {
            headers: {
                "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
            }
        }).then(response => sessionStorage.setItem("participantId", response.data._id));

        let room = await axios.get(`https://api.reso.chat/room/${roomId}`, {
            headers: {
                "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
            }
        });
        room = room.data.room;
        // Set timer
        let seconds = parseInt((new Date(room.startDateTime) - Date.now())/1000), minutes = 0;
        console.log(seconds, room)
        if(seconds > 60) {
            minutes = parseInt(seconds/60);
            seconds = seconds%60;
        }
        this.setState({
            minutes,
            seconds
        });

        myInterval = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState({seconds: this.state.seconds - 1});
            }
            else if (this.state.seconds === 0) {
                if (this.state.minutes === 0) {
                    clearInterval(myInterval);
                    
                    if(this.state.users.length >= 2) {
                        this.startChat();
                    }
                    else {
                        this.setState({ modalVisible: true });
                    }
                } else {
                    this.setState({minutes: this.state.minutes - 1});
                    this.setState({seconds: 59});
                }
            } 
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(myInterval);
        socket.emit("leaveRoom", roomId);
        socket.disconnect();
    }

    async startChat() {
        await axios.put(`https://api.reso.chat/room?id=${roomId}`, { status: 1, startDateTime: Date.now()}, {
            headers: {
                "Authorization": `Bearer ${REACT_APP_BOT_TOKEN}`
            }
        });
        payload = {
            ...payload,
            colorCode: this.state.me,
            nickname
        };
        let tokenNew = jwt.sign(payload, JWT_KEY);
        this.props.history.push(`/chat?auth=${tokenNew}`);
    }

    render() {
        // console.log("arrow",this.state.showScrollArrow);
        if(!roomId || roomId.length < 12) {
            return(
              <div />
            );
          }
        else {

            const { t } = this.props;

            return (
                <div className="WaitingRoom">
                    <div className="WaitingRoom__topBox">
                        <div className="WaitingRoom__title">{t('main.letstakechat')}</div>
                        <div className="WaitingRoom_titleSmall">{t('main.waiting4others')}</div>
                    </div>
        
                    <div className="WaitingRoom__participantBox">
                        
                        <div className="WaitingRoom__participantBox_top">
                            <div className="WaitingRoom__mediumText">{t('main.estimatedwaittime')} </div>
                            <div className="WaitingRoom__time">{this.state.minutes}:{this.state.seconds >= 10 ? this.state.seconds : `0${this.state.seconds}`}</div>
                        </div>
                        <div className="WaitingRoom__participantBox_bottom">
                            {
                                this.state.users.map((user) => (
                                    <>
                                        <div className="WaitingRoom__participantBubble">
                                            <div className="participant_circle" style={{backgroundColor: user.code}}>{user.code === this.state.me ? `${t("main.me")}` : user.code}</div>
                                            {user.nickname}
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
        
                    <div className="WaitingRoom__welcomeText">
                        { welcome_image &&  <img src={welcome_image} alt="welcome_image" className="WaitingRoom__welcome-image"/> }
                        
                         {
                             welcome_message !== false && welcome_message !== "<p></p>" ? (
                               <CustomHtml welcome_message={welcome_message}/>
                             ):(
                                <>
                                <div className="titleText">{t('main.welcome')}</div>
                                <div className="subtitleText">{t('main.letshavefun')}</div>
                                </>
                             )
                         }

                    </div>
                  
                   {this.state.showScrollArrow &&  <div className="WaitingRoom__scroll-wrapper">
                        <BsArrowDown className="scrollIcon"/>
                        <h5>{t("main.scrolldowntoview")}</h5>
                    </div> }

                    <WaitingRoom2 gameUrl={gameUrl} mediaUrl={mediaUrl}/>

                    {this.state.modalVisible && 
                    <SorryPopUp visible={this.state.modalVisible} close={() => this.setState({modalVisible: false})} prefixTimeoutCode={prefixTimeoutCode} REACT_APP_BOT_TOKEN={REACT_APP_BOT_TOKEN} waitTimeOutInstructions={waitTimeOutInstructions}/> }
                </div>
            )
        }
    }
}

export default withTranslation()(WaitingRoom)
